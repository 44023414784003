import "./App.css";
import Datepicker from "react-tailwindcss-datepicker";
import { useState } from "react";

function App() {
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  return (
    <div className="App">
      <Datepicker
        containerClassName="relative w-64"
        value={value}
        onChange={handleValueChange}
      />
    </div>
  );
}

export default App;
